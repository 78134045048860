import { CompanyDetailResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchCompanyDetailService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(companyId: number): Promise<CompanyDetailResponse> {
        return this.repository.getCompanyDetail(companyId);
    }
}
