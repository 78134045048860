// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useClipBoard = () => {
    const clipboardCopy = (text: string): void => {
        navigator.clipboard.writeText(text);
    };

    return {
        clipboardCopy,
    };
};
