






import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        flag: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const copyFlag = computed(() => props.flag);
        return {
            copyFlag,
        };
    },
});
