// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useSleep = () => {
    const sleep = async (time: number): Promise<void> => {
        await new Promise(resolve => setTimeout(resolve, time));
    };

    return {
        sleep,
    };
};
