import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { FetchCompanyDetailService } from '@/models/service/Management/FetchCompanyDetailService';
import { CompanyDetailInfo } from '@/models/interface/Management/ICompanyRepository';
import { CompanyDeleteService } from '@/models/service/Management/CompanyDeleteService';
import { useCircular } from '@/composables/function/Common/useCircular';
import { useClipBoard } from '@/composables/function/Common/useClipBoard';
import { useSleep } from '@/composables/function/Common/useSleep';
import { CompanyContractCancelService } from '@/models/service/Management/CompanyContractCancelService';
import { AutoStopSettingService } from '@/models/service/Management/AutoStopSettingService';

const CompanyDetail = container.resolve(FetchCompanyDetailService);
const CompanyDelete = container.resolve(CompanyDeleteService);
const ContractCancel = container.resolve(CompanyContractCancelService);
const UpdateAutoStopSetting = container.resolve(AutoStopSettingService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyDetail = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const companyDetail = ref<CompanyDetailInfo>();
    const isDeleteProgressing = ref(false);
    const isCompanyFetchProgressing = ref(false);
    const isContractCancelProgressing = ref(false);
    const isAutoStopSettingProgressing = ref(false);
    const copyFlag = ref<boolean>(false);

    provide('error', error);
    provide('companyDetail', companyDetail);
    provide('isDeleteProgressing', isDeleteProgressing);
    provide('isCompanyFetchProgressing', isCompanyFetchProgressing);
    provide('isContractCancelProgressing', isContractCancelProgressing);
    provide('isAutoStopSettingProgressing', isAutoStopSettingProgressing);
    provide('copyFlag', copyFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyDetail = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const companyDetail = inject('companyDetail') as Ref<CompanyDetailInfo>;
    const isDeleteProgressing = inject('isDeleteProgressing') as Ref<boolean>;
    const isCompanyFetchProgressing = inject('isCompanyFetchProgressing') as Ref<boolean>;
    const isContractCancelProgressing = inject('isContractCancelProgressing') as Ref<boolean>;
    const isAutoStopSettingProgressing = inject('isAutoStopSettingProgressing') as Ref<boolean>;
    const copyFlag = inject('copyFlag') as Ref<boolean>;

    const { progressStart, progressEnd } = useCircular();

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initCompany = (): void => {
        companyDetail.value = {} as CompanyDetailInfo;
    };

    const setCompanyDetail = (data: CompanyDetailInfo): void => {
        companyDetail.value = data;
    };

    const { clipboardCopy } = useClipBoard();
    const { sleep } = useSleep();
    const copy = async (apiKey: string): Promise<void> => {
        clipboardCopy(apiKey);
        copyFlag.value = true;
        await sleep(3000);
        copyFlag.value = false;
    };

    const fetchCompanyDetail = async (companyId: number): Promise<void> => {
        progressStart(isCompanyFetchProgressing);
        const response = await CompanyDetail.handle(companyId);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isCompanyFetchProgressing);
            return;
        }
        initStatus();
        setCompanyDetail(response.data.data);
        progressEnd(isCompanyFetchProgressing);
    };

    const deleteCompany = async (companyId: number): Promise<void> => {
        progressStart(isDeleteProgressing);
        const response = await CompanyDelete.handle([companyId]);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isDeleteProgressing);
            return;
        }

        initStatus();
        initCompany();
        progressEnd(isDeleteProgressing);
    };

    const cancelContract = async (contractId: number): Promise<void> => {
        progressStart(isContractCancelProgressing);
        const response = await ContractCancel.handle(contractId);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isContractCancelProgressing);
            return;
        }

        initStatus();
        progressEnd(isContractCancelProgressing);
    };

    const changeAutoStopSetting = async (): Promise<void> => {
        progressStart(isAutoStopSettingProgressing);
        const response = await UpdateAutoStopSetting.handle(companyDetail.value.id, !companyDetail.value.autoStop);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            progressEnd(isAutoStopSettingProgressing);
            return;
        }

        progressEnd(isAutoStopSettingProgressing);
    };

    const canDelete = (): boolean => {
        return companyDetail.value.tenantCount !== 0;
    };

    const isExistSchedulePlan = (): boolean => {
        return !!companyDetail.value.planHistories.find(plan => plan.isSchedule);
    };

    return {
        error,
        companyDetail,
        isDeleteProgressing,
        isCompanyFetchProgressing,
        isContractCancelProgressing,
        isAutoStopSettingProgressing,
        copyFlag,
        initStatus,
        initCompany,
        fetchCompanyDetail,
        deleteCompany,
        canDelete,
        cancelContract,
        copy,
        isExistSchedulePlan,
        changeAutoStopSetting,
    };
};
