





































import { defineComponent } from '@vue/composition-api';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { useTime } from '@/composables/function/Common/useTime';
import { useDate } from '@/composables/function/Common/useDate';

export default defineComponent({
    setup() {
        const { companyDetail, copy } = useCompanyDetail();

        const { convertToHourFormat } = useTime();

        const { getThisMonth } = useDate();

        return {
            companyDetail,
            copy,
            convertToHourFormat,
            getThisMonth,
        };
    },
});
