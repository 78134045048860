








































































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { useTime } from '@/composables/function/Common/useTime';
import { useDate } from '@/composables/function/Common/useDate';

export default defineComponent({
    setup() {
        const { companyDetail } = useCompanyDetail();

        const { convertToHourFormat } = useTime();

        const { getThisMonth } = useDate();

        const getAutoStopLabel = (autoStopType: boolean): string => {
            return autoStopType ? '停止する' : '停止しない';
        };

        return {
            companyDetail,
            convertToHourFormat,
            getThisMonth,
            getAutoStopLabel,
        };
    },
});
