import { inject, injectable } from 'tsyringe';
import { ContractScheduleCancelResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';

@injectable()
export class CompanyContractCancelService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(contractId: number): Promise<ContractScheduleCancelResponse> {
        return this.repository.contractScheduleCancel(contractId);
    }
}
