import { CompanyPlanInfo } from '@/models/interface/Management/ICompanyRepository';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { useCompanyDetail } from './useCompanyDetail';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideCompanyDetailConfirmDialog = () => {
    const detailConfirmDialog = ref(false);
    const cancelTargetContract = ref<CompanyPlanInfo>();

    provide('detailConfirmDialog', detailConfirmDialog);
    provide('cancelTargetContract', cancelTargetContract);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useCompanyDetailConfirmDialog = () => {
    const detailConfirmDialog = inject('detailConfirmDialog') as Ref<boolean>;
    const cancelTargetContract = inject('cancelTargetContract') as Ref<CompanyPlanInfo>;

    const openCancelDialog = (target: CompanyPlanInfo): void => {
        cancelTargetContract.value = target;
        detailConfirmDialog.value = true;
    };

    const { initStatus } = useCompanyDetail();

    const closeDialog = (): void => {
        initStatus();
        detailConfirmDialog.value = false;
    };

    return {
        detailConfirmDialog,
        cancelTargetContract,
        openCancelDialog,
        closeDialog,
    };
};
