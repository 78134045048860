import { CompanyRegistrationResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class AutoStopSettingService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(companyId: number, autoStop: boolean): Promise<CompanyRegistrationResponse> {
        return this.repository.postAutoStopSettingUpdate(companyId, autoStop);
    }
}
