import { inject, injectable } from 'tsyringe';
import { CompanyDeleteResponse, ICompanyRepository } from '@/models/interface/Management/ICompanyRepository';

@injectable()
export class CompanyDeleteService {
    constructor(@inject('ICompanyRepository') private readonly repository: ICompanyRepository) {}

    async handle(ids: number[]): Promise<CompanyDeleteResponse> {
        return this.repository.deleteCompany(ids);
    }
}
