





























import { defineComponent } from '@vue/composition-api';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { useCompanyDetailConfirmDialog } from '@/composables/store/Management/useCompanyDetailConfirmDialog';

export default defineComponent({
    setup() {
        const { companyDetail } = useCompanyDetail();
        const { openCancelDialog } = useCompanyDetailConfirmDialog();

        return {
            companyDetail,
            openCancelDialog,
        };
    },
});
