import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAutoStopConfirmDialog = () => {
    const autoStopConfirmDialog = ref(false);

    provide('autoStopConfirmDialog', autoStopConfirmDialog);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAutoStopConfirmDialog = () => {
    const autoStopConfirmDialog = inject('autoStopConfirmDialog') as Ref<boolean>;

    const openAutoStopDialog = (): void => {
        autoStopConfirmDialog.value = true;
    };

    const closeDialog = (): void => {
        autoStopConfirmDialog.value = false;
    };

    return {
        autoStopConfirmDialog,
        openAutoStopDialog,
        closeDialog,
    };
};
