













































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CompanyDetailTableComponent from '@/components/Management/organisms/CompanyDetailTable.vue';
import CompanyMenuComponent from '@/components/Management/molecules/CompanyMenu.vue';
import CompanyDetailTenantTableComponent from '@/components/Management/molecules/CompanyDetailTenantTable.vue';
import CompanyDetailPlanHistoryComponent from '@/components/Management/molecules/CompanyDetailPlanHistory.vue';
import CompanyDetailAlertComponent from '@/components/Management/molecules/CompanyDetailAlert.vue';
import CopyAlertComponent from '@/components/Management/molecules/CopyAlert.vue';
import { useCompanyMenu } from '@/composables/store/Management/useCompanyMenu';
import { useCompanyDetail } from '@/composables/store/Management/useCompanyDetail';
import { useCompanyPlan } from '@/composables/store/Management/AnalysisPlan/useCompanyPlan';
import { useRouter } from '@/composables/function/Common/useRouter';
import { useAutoStopConfirmDialog } from '@/composables/store/Management/useAutoStopConfirmDialog';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CompanyDetailTable: CompanyDetailTableComponent,
        CompanyMenu: CompanyMenuComponent,
        CompanyDetailTenantTable: CompanyDetailTenantTableComponent,
        CompanyDetailPlanHistory: CompanyDetailPlanHistoryComponent,
        CompanyDetailAlert: CompanyDetailAlertComponent,
        CopyAlert: CopyAlertComponent,
    },
    setup() {
        const {
            isDefaultSelectCompany,
            selectedCompany,
            setCompanyName,
            setSearchCompanyName,
            initCompanyName,
            initSelectedCompany,
            fetchCompanyList,
            setSelectedCompany,
        } = useCompanyMenu();
        const {
            error,
            fetchCompanyDetail,
            companyDetail,
            initCompany,
            deleteCompany,
            canDelete,
            isDeleteProgressing,
            isCompanyFetchProgressing,
            copyFlag,
            isExistSchedulePlan,
        } = useCompanyDetail();
        const router = useRouter();
        const menuFlag = ref(false);
        const { openAutoStopDialog } = useAutoStopConfirmDialog();

        onMounted(() => {
            initCompany();

            const { id, name } = router.currentRoute.params;
            if (id) {
                setSelectedCompany(Number(id));
                //NOTE: 会社新規登録時に再度会社全件取得を待ちたくない為、ダイレクトに会社名を設定
                setCompanyName(name);
                fetchCompanyDetail(Number(id));
                fetchCompanyList();
            }
        });

        watch(selectedCompany, () => {
            if (isDefaultSelectCompany()) return;

            setSearchCompanyName(selectedCompany.value);
            fetchCompanyDetail(selectedCompany.value);
        });

        const execute = async (companyId: number): Promise<void> => {
            await deleteCompany(companyId);

            if (!error.value.hasError) {
                initSelectedCompany();
                initCompanyName();
                fetchCompanyList();
            }
        };

        const { setTargetCompany } = useCompanyPlan();
        const pushToCompanyPlan = (companyId: number): void => {
            setTargetCompany({ id: companyId, name: companyDetail.value.name });
            router.push({
                name: 'CompanyPlan',
                params: { id: `${companyId}` },
            });
        };

        const deletingClass = (): string => (isDeleteProgressing.value ? 'deleting' : '');

        const getChangeButtonLabel = (autoStop: boolean): string => (autoStop ? '無効' : '有効');

        return {
            menuFlag,
            companyDetail,
            error,
            isDefaultSelectCompany,
            canDelete,
            execute,
            isDeleteProgressing,
            isCompanyFetchProgressing,
            copyFlag,
            deletingClass,
            pushToCompanyPlan,
            isExistSchedulePlan,
            getChangeButtonLabel,
            openAutoStopDialog,
        };
    },
});
